import React from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import get from 'lodash/get';
import Layout from '../components/layout';
import Container from '../components/container';

class PrivacyPolicyPage extends React.Component {
    render() {
        const page = get(this, 'props.data.contentfulPage.body');
        const links = get(this, 'props.data.allContentfulLink.nodes') || [{}];

        return (
            <Layout links={links}>
                <Container>
                    {renderRichText(page)}
                </Container>
            </Layout>
        );
    }
}

export default PrivacyPolicyPage;

export const privacyPolicyPage = graphql`
    query PrivacyPolicyPage {
        contentfulPage(title: { eq: "privacy-policy" }) {
            body {
                raw
            }
        }
        allContentfulLink {
            nodes {
                title
                link
                shouldHideIcon
            }
        }
    }
`;
